<template>
    <div class="logo-container">
        <router-link to="orla-login">
            <img src="/orla/logo.png" alt="ORLA"/>
        </router-link>
    </div>
</template>
<script>
export default {
    mounted: function () {
        document.querySelector('title').textContent = "ORLA";
    }
}
</script>
